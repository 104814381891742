@font-face { 
  font-family: 'Roboto Bold';
  font-style: normal;
  font-display: swap;
  src: local('Roboto Bold'),
    url('./data/Roboto-Bold.woff2') format('woff2'),
    url('./data/Roboto-Bold.woff') format('woff'),
    url('./data/Roboto-Bold.ttf') format ('truetype'); 
}
@font-face { 
  font-family: 'Roboto Light';
  font-style: normal;
  font-display: swap;
  src: local('Roboto Light'),
    url('./data/Roboto-Light.woff2') format('woff2'),
    url('./data/Roboto-Light.woff') format('woff'),
    url('./data/Roboto-Light.ttf') format('truetype');
}      

body {
  margin: 0;
  font-family: 'Roboto Light', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}